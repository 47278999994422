import React from 'react'
import {Menu} from 'antd'
import {HomeOutlined, FormOutlined, FileTextOutlined, CalendarOutlined, FileAddOutlined} from '@ant-design/icons'
import { EditOutlined, SettingOutlined} from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

const MenuList = () => {

  const getCurrentMonth = () => {
    const month = new Date().getMonth() + 1;
    return month;
  }

  const getCurrentYear = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear()
    return year;
  }

  const navigate = useNavigate()

  const toAnneeFiscale = () => {
    navigate('/utilisateur/anneeFiscale')
  }

  const toDashboard = () => {
    navigate('/utilisateur/')
  }

  const toCodeJournale = () => {
    navigate('/utilisateur/codeJournale')
  }

  const toPlanComptable = () => {
    navigate('/utilisateur/planComptable')
  }

  const toProfil = () => {
    navigate('/utilisateur/profil')
  }

  const toJournalSaisie = () => {
    navigate('/utilisateur/journalSaisie/' + getCurrentMonth() + '/' + getCurrentYear())
  }

  return (
    <Menu theme='light' className='pl-2 mt-3 h-[88vh] flex flex-col gap-[5px]' mode='inline'>
      <Menu.Item onClick={toDashboard} style={{ color: '#949494' }}  className='s font-semibold' key="Tableau-bord" icon={<HomeOutlined style={{ color: '#0071BD' }}/>}>
        Tableau de bord
      </Menu.Item>

      <Menu.Item onClick={toCodeJournale} style={{ color: '#949494' }}  className='s font-semibold' key="Journale-saisie" icon={<FormOutlined style={{ color: '#0071BD' }}/>}>
        Code journal
      </Menu.Item>

      <Menu.Item onClick={toPlanComptable} style={{ color: '#949494' }}  className='s font-semibold' key="Plan-comptable" icon={<FileTextOutlined style={{ color: '#0071BD' }}/>}>
        Plan comptable
      </Menu.Item>

      <Menu.Item onClick={toAnneeFiscale} style={{ color: '#949494' }}  className='s font-semibold' key="Code-journal" icon={<CalendarOutlined style={{ color: '#0071BD' }}/>}>
         Année fiscale
      </Menu.Item>

      <Menu.Item onClick={toJournalSaisie} style={{ color: '#949494' }}  className='s font-semibold' key="Annee-fiscale" icon={<FileAddOutlined style={{ color: '#0071BD' }}/>}>
         Journal de saisie
      </Menu.Item>

      <Menu.Item onClick={toProfil} style={{ color: '#949494' }}  className='s font-semibold' key="Profil" icon={<SettingOutlined style={{ color: '#0071BD' }}/>}>
        Profil
      </Menu.Item>

      {/* <Menu.SubMenu style={{ color: '#949494' }}  className='s font-semibold' key="Parametre" icon={<SettingOutlined style={{ color: '#0071BD' }}/>} title={<span style={{ color: '#949494' }}>Paramètre</span>}>
         <Menu.Item onClick={toEntite} key="Entité" className=''>Entité</Menu.Item>
         <Menu.Item onClick={toProfil} key="Profil">Profil</Menu.Item>
      </Menu.SubMenu> */}
    </Menu>
  )
}

export default MenuList