import React from 'react'
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Accueil from './Pages/Accueil/Accueil'
import Login from './Pages/Login/Login'
import PrivateRoute from './PrivateRoute'

// import Dashboard from './Pages/Dashboard/Dashboard'
// import Categorie from './Pages/Categorie/Categorie'
// import Transaction from './Pages/Transaction/Transactions'
// import Banque from './Pages/Banque/Banque'
// import Caisse from './Pages/Caisse/Caisse'

import Inscription from './Pages/Inscription/Inscription'
import VerifyEmail from './Pages/Inscription/VerifyEmail'
import Bienvenu from './Pages/Inscription/Bienvenu'
import Demande from './Pages/Demande/Demande'
import VerificationEmail from './Pages/ForgotPassword/VerificationEmail'
import ChangeMDP from './Pages/ForgotPassword/ChangePwd'
import DashboardAdmin from './Pages/Admin/Dashboard/Dashboard'
import AnneeFiscaleAdmin from './Pages/Admin/AnneeFiscale/AnneeFiscale'
import ChoixMoisAdmin from './Pages/Admin/AnneeFiscale/ChoixMois'
import ChoixMenuAdmin from './Pages/Admin/AnneeFiscale/ChoixMenu'
import CodeJournaleAdmin from './Pages/Admin/CodeJournale/CodeJournale'
import PlanComptableAdmin from './Pages/Admin/PlanComptable/PlanComptable'
import UtilisateurAdmin from './Pages/Admin/Utilisateur/Utilisateur'
import DetailUtilisateurAdmin from './Pages/Admin/Utilisateur/DetailUtilisateur'
import AjoutUtilisateurAdmin from './Pages/Admin/Utilisateur/AjoutCompte'
import ProfilAdmin from './Pages/Admin/Parametre/Profil'
import EntiteAdmin from './Pages/Admin/Parametre/Entite'
import ActuelleAdmin from './Pages/Admin/Abonnement/Actuelle'
import DemandeAdmin from './Pages/Admin/Abonnement/Demande'
import EnvoyerDemandeAdmin from './Pages/Admin/Abonnement/EnvoyerDemande'
import DetailDemandeAdmin from './Pages/Admin/Abonnement/DetailDemande'
import HistoriqueAboAdmin from './Pages/Admin/Abonnement/Historique'
import JournalSaisieAdmin from './Pages/Admin/JournalSaisie/JournalSaisie'
import BilanAdmin from './Pages/Admin/Bilan/Bilan'
import RecapitulationAdmin from './Pages/Admin/Recapitulation/Recapitulation'
import BalanceGeneraleAdmin from './Pages/Admin/BalanceGenerale/BalanceGenerale'
import RapportFinancierAdmin from './Pages/Admin/RapportFinancier/RapportFinancier'
import BilanAnnuelAdmin from './Pages/Admin/BilanAnnuel/BilanAnnuel'
import FactureAdmin from './Pages/Admin/Abonnement/Facture'

// Utilisateur
import DashboardUser from './Pages/Utilisateur/Dashboard/Dashboard'
import CodeJournaleUser from './Pages/Utilisateur/CodeJournale/CodeJournale'
import PlanComptableUser from './Pages/Utilisateur/PlanComptable/PlanComptable'
import ProfilUser from './Pages/Utilisateur/Parametre/Profil'
import AnneeFiscaleUser from './Pages/Utilisateur/AnneeFiscale/AnneeFiscale'
import ChoixMoisUser from './Pages/Utilisateur/AnneeFiscale/ChoixMois'
import ChoixMenuUser from './Pages/Utilisateur/AnneeFiscale/ChoixMenu'
import JournalSaisieUser from './Pages/Utilisateur/JournalSaisie/JournalSaisie'
import RapportFinancierUser from './Pages/Utilisateur/RapportFinancier/RapportFinancier'
import BilanUser from './Pages/Utilisateur/Bilan/Bilan'
import BilanAnnuelUser from './Pages/Utilisateur/BilanAnnuel/BilanAnnuel'
import BalanceGeneraleUser from './Pages/Utilisateur/BalanceGenerale/BalanceGenerale'
import RecapitulationUser from './Pages/Utilisateur/Recapitulation/Recapitulation'

// Super admin
import DashboardSuperAdmin from './Pages/SuperAdmin/Dashboard/Dashboard' 
import UtilisateurSuperAdmin from './Pages/SuperAdmin/Utilisateur/Utilisateur'
import DetailUtilisateurSuperAdmin from './Pages/SuperAdmin/Utilisateur/DetailUtilisateur'
import AbonnementSuperAdmin from './Pages/SuperAdmin/Abonnement/Abonnement'
import DemandeSuperAdmin from './Pages/SuperAdmin/Demande/Demande'
import DetailDemandeSuperAdmin from './Pages/SuperAdmin/Demande/DetailDemande'
import ProfilSuperAdmin from './Pages/SuperAdmin/Profil/Profil'
import CodeJournaleSuperAdmin from './Pages/SuperAdmin/CodeJournale/CodeJournale'
import PlanComptableSuperAdmin from './Pages/SuperAdmin/PlanComptable/PlanComptable'
import HistoriqueSuperAdmin from './Pages/SuperAdmin/Historique/Historique'

const Router = () => {
  return (
    <div>
        
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Accueil/>} />
          <Route path="/Connexion" element={<Login/>} />
          <Route path="/Inscription/:email/:token" element={<Inscription/>} />
          <Route path="/verification" element={<VerifyEmail/>} />
          <Route path="/bienvenue" element={<Bienvenu/>} />
          <Route path='/abonnement' element={<PrivateRoute element={<Demande/>}/>}></Route>
          <Route path='/verificationEmail' element={<VerificationEmail/>}></Route>
          <Route path='/changer-mdp/:email' element={<ChangeMDP/>}></Route>

          {/* Routes Admin */}
            <Route path='/Admin/' element={<PrivateRoute element={<DashboardAdmin />} />}></Route>
            {/* <Route path='/Admin/anneeFiscale' element={<PrivateRoute element={<AnneeFiscaleAdmin/>} />}></Route> */}
            {/* <Route path='/admin/' element={<DashboardAdmin />}></Route> */}
            <Route path='/admin/anneeFiscale' element={<PrivateRoute element={<AnneeFiscaleAdmin/>}/>}> </Route>
            <Route path='/admin/anneeFiscale/:annee' element={<PrivateRoute element={<ChoixMoisAdmin/>}/>}></Route>
            <Route path='/admin/anneeFiscale/:annee/:mois' element={<PrivateRoute element={<ChoixMenuAdmin/>}/>}></Route>
            <Route path='/admin/codeJournale' element={<PrivateRoute element={<CodeJournaleAdmin />}/>}></Route>
            <Route path='/admin/planComptable' element={<PrivateRoute element={<PlanComptableAdmin/>}/>}></Route>
            <Route path='/admin/utilisateur' element={<PrivateRoute element={<UtilisateurAdmin/>}/>}></Route>
            <Route path='/admin/utilisateur/:id' element={<PrivateRoute element={<DetailUtilisateurAdmin/>}/>}></Route>
            <Route path='/admin/utilisateur/ajout' element={<PrivateRoute element={<AjoutUtilisateurAdmin/>}/>}></Route>
            <Route path='/admin/profil' element={<PrivateRoute element={<ProfilAdmin/>}/>}></Route>
            <Route path='/admin/entite' element={<PrivateRoute element={<EntiteAdmin/>}/>}></Route>
            <Route path='/admin/actuel' element={<PrivateRoute element={<ActuelleAdmin/>}/>}></Route>
            <Route path='/admin/facture/:id' element={<PrivateRoute element={<FactureAdmin/>}/>}></Route>
            <Route path='/admin/demande' element={<PrivateRoute element={<DemandeAdmin/>}/>}></Route>
            <Route path='/admin/envoyerDemande' element={<PrivateRoute element={<EnvoyerDemandeAdmin/>}/>}></Route>
            <Route path='/admin/demande/:id' element={<PrivateRoute element={<DetailDemandeAdmin/>}/>}></Route>
            <Route path='/admin/historique' element={<PrivateRoute element={<HistoriqueAboAdmin/>}/>}></Route>
            <Route path='/admin/journalSaisie/:mois/:annee' element={<PrivateRoute element={<JournalSaisieAdmin/>}/>}></Route>
            <Route path='/admin/bilan/:mois/:annee' element={<PrivateRoute element={<BilanAdmin/>}/>}></Route>
            <Route path='/admin/recapitulation/:mois/:annee' element={<PrivateRoute element={<RecapitulationAdmin/>}/>}></Route>
            <Route path='/admin/balanceGenerale/:mois/:annee' element={<PrivateRoute element={<BalanceGeneraleAdmin/>}/>}></Route>
            <Route path='/admin/rapportFinancier/:mois/:annee' element={<PrivateRoute element={<RapportFinancierAdmin/>}/>}></Route>
            <Route path='/admin/bilanAnnuel/:annee' element={<PrivateRoute element={<BilanAnnuelAdmin/>}/>}></Route>

          {/* Routes Utilisateur */}
          <Route path='/Utilisateur/' element={<PrivateRoute element={<DashboardUser/>}/>}></Route>
          <Route path='/Utilisateur/codeJournale' element={<PrivateRoute element={<CodeJournaleUser/>}/>}></Route>
          <Route path='/Utilisateur/planComptable' element={<PrivateRoute element={<PlanComptableUser/>}/>}></Route>
          <Route path='/Utilisateur/profil' element={<PrivateRoute element={<ProfilUser/>}/>}></Route>
          <Route path='/Utilisateur/anneeFiscale' element={<PrivateRoute element={<AnneeFiscaleUser/>}/>}></Route>
          <Route path='/Utilisateur/anneeFiscale/:annee' element={<PrivateRoute element={<ChoixMoisUser/>}/>}></Route>
          <Route path='/Utilisateur/anneeFiscale/:annee/:mois' element={<PrivateRoute element={<ChoixMenuUser/>}/>}></Route>
          <Route path='/Utilisateur/journalSaisie/:mois/:annee' element={<PrivateRoute element={<JournalSaisieUser/>}/>}></Route>
          <Route path='/Utilisateur/rapportFinancier/:mois/:annee' element={<PrivateRoute element={<RapportFinancierUser/>}/>}></Route>
          <Route path='/Utilisateur/bilan/:mois/:annee' element={<PrivateRoute element={<BilanUser/>}/>}></Route>
          <Route path='/Utilisateur/balanceGenerale/:mois/:annee' element={<PrivateRoute element={<BalanceGeneraleUser/>}/>}></Route>
          <Route path='/Utilisateur/recapitulation/:mois/:annee' element={<PrivateRoute element={<RecapitulationUser/>}/>}></Route>
          <Route path='/Utilisateur/bilanAnnuel/:annee' element={<PrivateRoute element={<BilanAnnuelUser/>}/>}></Route>


          {/* Routes super admin */}
          <Route path='/superAdmin/' element={<PrivateRoute element={<DashboardSuperAdmin/>}/>}></Route>
          <Route path='/superAdmin/utilisateur' element={<PrivateRoute element={<UtilisateurSuperAdmin/>}/>}></Route>
          <Route path='/superAdmin/utilisateur/:id' element={<PrivateRoute element={<DetailUtilisateurSuperAdmin/>}/>}></Route>
          <Route path='/superAdmin/abonnement' element={<PrivateRoute element={<AbonnementSuperAdmin/>}/>}></Route>
          <Route path='/superAdmin/demande' element={<PrivateRoute element={<DemandeSuperAdmin/>}/>}></Route>
          <Route path='/superAdmin/demande/:id' element={<PrivateRoute element={<DetailDemandeSuperAdmin/>}/>}></Route>
          <Route path='/superAdmin/profil' element={<PrivateRoute element={<ProfilSuperAdmin/>}/>}></Route>
          <Route path='/superAdmin/codeJournale' element={<PrivateRoute element={<CodeJournaleSuperAdmin/>}/>}></Route>
          <Route path='/superAdmin/planComptable' element={<PrivateRoute element={<PlanComptableSuperAdmin/>}/>}></Route>
          <Route path='/superAdmin/historique' element={<PrivateRoute element={<HistoriqueSuperAdmin/>}/>}></Route>

        </Routes>
      </BrowserRouter>

    </div>
  )
}

export default Router