import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element }) => {
  const token = sessionStorage.getItem('token'); // ou localStorage.getItem('token')

  return token ? element : <Navigate to="/Connexion" />;
};

export default PrivateRoute;
