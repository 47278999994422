import React from 'react'
import img3 from '../Assets/img3.jpg'
import tsena from '../Assets/tsena.jpeg'
import asa from '../Assets/asa.jpeg'
import natura from '../Assets/natura.jpeg'
import Carousel from './Carousel'
import Logo from '../Assets/Logo.png'

const Partenaire = () => {

  const images = [
    tsena,
    asa,
    natura,
    Logo,
    tsena
  ];

  return (
    <div className='md:px-24 p-4 mx-auto'>

      <h1 className='font-bold text-2xl mb-3 text-center md:mt-8 md:mb-6'><span className='border-primary border-b-2 py-1'>Les partenaires</span></h1>
      
      {/* <div className='flex flex-col md:flex-row justify-between items-center gap-10'>
          <div className='md:w-1/4'>
            <img src={tsena} alt="" className='lg:h-[150px] max-sm:h-[120px]' />
          </div>

          <div className='md:w-1/4'>
            <img src={asa} alt="" className='lg:h-[150px] max-sm:h-[120px]' />
          </div>
          <div className='md:w-1/4'>
            <img src={natura} alt="" className='lg:h-[150px] max-sm:h-[120px]' />
          </div>
          <div className='md:w-1/4'>
            <img src={tsena} alt="" className='lg:h-[150px] max-sm:h-[120px]' />
          </div>
          <div className='md:w-1/4'>
            <img src={tsena} alt="" className='lg:h-[150px] max-sm:h-[120px]' />
          </div>
        </div> */}

    <div className="container mx-auto p-4">
      <Carousel images={images} />
    </div>
        
    </div>
  )
}

export default Partenaire