import axios from "axios";
import React, { useState } from "react";
import { FaSpinner } from "react-icons/fa";
import { api } from "../api";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    objet: ""
  });

  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Vérification d'un email valide
    if (!/\S+@\S+\.\S+/.test(formData.email)) {
      setStatus("Veuillez entrer un email valide.");
      return;
    }

    setLoading(true);
    setStatus("Envoi en cours...");
    
    try {
      axios.post(api + '/api/contacter', formData)
        .then(res => {
          setStatus("Message envoyé avec succès!");
          setLoading(false);
          setFormData({ name: "", email: "", message: "", objet: "" }); // Réinitialisation
        })
      // console.log("Données du formulaire :", formData);
      // setTimeout(() => {
      //   setStatus("Message envoyé avec succès !");
      //   setLoading(false);
      //   setFormData({ name: "", email: "", message: "", objet: "" }); // Réinitialisation
      // }, 1500);
    } catch (error) {
      setStatus("Une erreur s'est produite. Veuillez réessayer.");
      setLoading(false);
    }
  };

  return (
    <div className="max-w-3xl mx-auto mt-10 p-6 bg-white shadow-md rounded-lg transition duration-500 hover:shadow-lg">
      <h2 className="text-2xl font-bold mb-7 text-center text-gray-800 animate-fadeIn">
      <span className='border-primary border-b-2 py-1'>Contactez-nous</span>
      </h2>
      <form onSubmit={handleSubmit} className="animate-fadeInUp">
        <div className="space-y-4">
          <div className="grid lg:grid-cols-2 gap-4">
            <input
              type="text"
              placeholder="Votre nom"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-300"
            />

            <input
              type="email"
              placeholder="Votre e-mail"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-300"
            />
          </div>

          <input
            type="text"
            placeholder="Objet"
            id="objet"
            name="objet"
            value={formData.objet}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-300"
          />

          <textarea
            id="message"
            name="message"
            placeholder="Message"
            value={formData.message}
            onChange={handleChange}
            required
            rows="4"
            className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-300"
          />
        </div>

        <div className="flex justify-center">
          <button
            type="submit"
            disabled={loading}
            className={`flex justify-center items-center gap-2 bg-blue-500 text-white py-1 px-4 mt-3 rounded transition duration-300 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            } mx-auto`}
          >
            {loading && <FaSpinner className="animate-spin" />}
            {loading ? "Envoi en cours..." : "Envoyer"}
          </button>
        </div>

      </form>

      {status && (
        <p className="mt-4 text-center text-gray-700 animate-fadeIn">{status}</p>
      )}
    </div>
  );
};

export default Contact;
