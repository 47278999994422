import React, { useState } from 'react';
import Logo from '../../Assets/Logo.png';
import { LeftCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { api } from '../../api';
import axios from 'axios';

const VerificationEmail = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const navigate = useNavigate();

  const revenir = () => navigate('/Connexion');

  const verification = async () => {
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      const trimmedEmail = email.trim();

      if (!trimmedEmail) {
        setError('Veuillez saisir votre adresse e-mail.');
        return;
      }

      const response = await axios.post(`${api}/api/verificationEmail`, { email: trimmedEmail });

      if (response.data.exist) {
        await axios.post(`${api}/api/envoyerLienForgotPassword`, { email: trimmedEmail });

        setSuccess('Le lien a été envoyé à votre adresse email.');
        setTimeout(() => navigate('/'), 4000);

      } else {
        setError('Aucun utilisateur trouvé avec cet email.');
      }
    } catch (err) {
      setError(err.response?.data?.message || 'Cette e-mail n\'a pas encore un compte. ');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-3">
        <div>
          <span onClick={revenir}>
            <LeftCircleOutlined style={{ fontSize: '25px', color: '#0071BD' }} className="cursor-pointer" />
          </span>
        </div>
        <div className="flex items-center justify-center">
          <img src={Logo} className="w-48 md:w-56" alt="Logo" />
        </div>
        <h2 className="mt-3 text-center text-3xl font-extrabold text-gray-900">Mot de passe oublié</h2>

        {error && <div className="text-red-600 text-center font-semibold">{error}</div>}
        {success && <div className="text-green-500 text-center font-semibold">{success}</div>}

        <p className="text-justify">
          Nous vous envoyons à votre adresse e-mail un lien de confirmation. Pour changer votre mot de passe, veuillez cliquer sur ce lien pour continuer les étapes.
        </p>

        <form className="mt-10 space-y-4" onSubmit={(e) => e.preventDefault()}>
          <div className="space-y-2">
            <label htmlFor="email" className="font-semibold">Adresse e-mail</label>
            <input
              id="email"
              type="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="appearance-none block w-full px-3 py-2 border border-gray-300 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Votre e-mail"
            />
          </div>
          
          <button
            type="button"
            onClick={verification}
            disabled={loading}
            className={`group mt-4 w-full flex justify-center py-2 px-4 text-sm font-medium rounded-md text-white bg-primary hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {loading ? 'En cours...' : 'Envoyer'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default VerificationEmail;
