import React, { useEffect, useState } from 'react'
import SideBarKaonty from '../../../Components/SideBarKaonty'
import Header from '../../../Components/Admin/Header'
import { Dropdown, Space } from 'antd';
import { DownOutlined,EllipsisOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { api } from '../../../api';
import axios from 'axios';

const Demande = () => {

  const navigate = useNavigate()
  const user_id = sessionStorage.getItem('user_id');

  // const data = [
  //   { id: 1, date: '26 Septembre 2024', type: 'Standard', prix: '50 000 Ar', etat: 'En attente' },
  //   { id: 2, date: '26 Juin 2024', type: 'Standard', prix: '50 000 Ar', etat: 'Accepté' },
  //   { id: 3, date: '26 Septembre 2024', type: 'Standard', prix: '600 000 Ar', etat: 'En attente' },
  //   { id: 4, date: '26 Février 2024', type: 'Standard', prix: '50 000 Ar', etat: 'Accepté' },
  // ];

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true);

  // Tous les demandes
  const getAllDemandes = async () => {
    try {
      const response = await axios.get(api + `/api/allDemandeUser/${user_id}`);
      setData(response.data);
      setLoading(false);

    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  useEffect(() => {
    getAllDemandes();
  }, []);

  // Fonction pour rediriger vers la page des détails
  const  detailDemande = (id) => {
    navigate(`/admin/demande/${id}`);
  };

  const  envoyerDemande = () => {
    navigate(`/admin/envoyerDemande`);
  };

  const items = (id) => [
    {
      label: <p className='f font-semibold hover:text-primary' onClick={() => detailDemande(id)}>Détails</p>,
      key: '0',
    }
  ];

  return (
    <div className='bg flex flex-row' style={{ height: '100vh' }}>
      <div>
        <SideBarKaonty />
      </div>
      <div className='bg w-full' style={{ background: '#F9F9FC' }}>
        <div>
          <Header titre={'Abonnement'}/>
        </div>
        <div className='pl-8 pr-7 py-6'>
          <div className='flex justify-between'>
            <div>
              <span className='text-lg font-semibold'>Demande(s)</span>
            </div>
            <div>
              <button className='px-4 py-1 font-semibold bg-white text-primary duration-300 border-primary rounded border-2 hover:text-white hover:bg-primary' onClick={envoyerDemande}>Envoyer une demande</button>
            </div>
          </div>

          {/* tableau */}
          <div className="overflow-x-auto mt-5 rounded shadow-lg">
            <table className="min-w-full bg-white border border-gray-200 rounded-lg">
              <thead>
                <tr className="bg-gray-400 text-white uppercase text-sm border-b">
                  <th className="py-3 px-4 text-left">Date</th>
                  <th className="py-3 px-4 text-left">Type</th>
                  <th className="py-3 px-4 text-left">Prix (Ar)</th>
                  <th className="py-3 px-4 text-left">État</th>
                  <th className="py-3 px-4 text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="5">
                      <div className="flex justify-center items-center py-10">
                        <div className="w-12 h-12 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
                      </div>
                    </td>
                  </tr>
                ) : data.length === 0 ? (
                  <tr>
                    <td colSpan="5">
                      <div className="text-center py-5 text-gray-500">
                        Aucune donnée disponible.
                      </div>
                    </td>
                  </tr>
                ) : (
                  data.map((demande) => (
                    <tr
                      key={demande.id}
                      className="hover:bg-gray-50 transition-colors border-b last:border-0"
                    >
                      <td className="py-3 px-4">
                        {new Date(demande.date).toLocaleDateString('fr-FR', {
                          day: 'numeric',
                          month: 'long',
                          year: 'numeric',
                        })}
                      </td>
                      <td className="py-3 px-4">{demande.type}</td>
                      <td className="py-3 px-4">
                        {parseInt(demande.prix).toLocaleString('fr-FR')}
                      </td>
                      <td
                        className={`py-3 px-4 font-medium ${
                          demande.etat === 'ACCEPTEE'
                            ? 'text-blue-500'
                            : demande.etat === 'EN ATTENTE'
                            ? 'text-green-500'
                            : 'text-gray-500'
                        }`}
                      >
                        <span
                          className={`border px-3 py-1 rounded-full text-sm ${
                            demande.etat === 'ACCEPTEE'
                              ? 'border-blue-500 bg-blue-50'
                              : demande.etat === 'EN ATTENTE'
                              ? 'border-green-500 bg-green-50'
                              : 'border-gray-300 bg-gray-100'
                          }`}
                        >
                          {demande.etat}
                        </span>
                      </td>
                      <td className="py-3 px-4 text-center">
                        <Dropdown
                          menu={{
                            items: items(demande.id),
                          }}
                          trigger={['click']}
                        >
                          <a
                            onClick={(e) => e.preventDefault()}
                            className="text-gray-800 font-medium hover:text-blue-600 transition-colors"
                          >
                            <Space>
                              <EllipsisOutlined
                                style={{ fontSize: '20px', color: '#0071BD' }}
                                className="cursor-pointer"
                              />
                            </Space>
                          </a>
                        </Dropdown>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>


        </div>
      </div>
      
    </div>
  )
}

export default Demande

