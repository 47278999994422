import React, { useEffect, useState } from 'react'
import SideBarKaonty from '../../../Components/SideBarKaonty'
import Header from '../../../Components/Admin/Header'
import axios from 'axios'
import { api } from '../../../api'
import { useNavigate } from 'react-router-dom'

const Dashboard = () => {
  const navigate = useNavigate()
  const user_id = sessionStorage.getItem('user_id');
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([])

  // Mon abonnement actuel
  const getAboActuel = async () => {
    try {
      const response = await axios.get(api + `/api/aboActuel/${user_id}`);
      console.log(response.data)
      setData(response.data[0]);
      setLoading(false);

    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const toFacture = () => {
    navigate('/admin/facture/' + data.id)
  }

  useEffect(() => {
    getAboActuel();
  }, []);
  
  return (
    <div className='bg flex flex-row' style={{ height: '100vh' }}>
      <div>
        <SideBarKaonty />
      </div>
      <div className='bg w-full' style={{ background: '#F9F9FC' }}>
        <div>
          <Header titre={'Abonnement'}/>
        </div>

        <div className='pl-8 pr-7 py-7'>
          <div className='flex justify-between'>
            <span className='text-lg font-semibold'>Information sur mon abonnement actuel</span>
            <button className='px-4 py-1 font-semibold bg-white text-primary duration-300 border-primary rounded border-2 hover:text-white hover:bg-primary' onClick={toFacture}>Générer un facture</button>
          </div>


          <div className="border-2 mt-7 p-7 rounded shadow-md bg-white">
              {loading ? (
                // Spinner de chargement
                <div className="flex justify-center items-center">
                  <div className="w-12 h-12 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
                </div>
              ) : (
                <>
                  {/* Type et Prix */}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="flex items-center space-x-4">
                      <span className="text-lg font-semibold text-gray-700">Type :</span>
                      <span className="text-lg font-semibold text-primary">{data.type}</span>
                    </div>

                    <div className="flex items-center space-x-4">
                      <span className="text-lg font-semibold text-gray-700">Prix :</span>
                      <span className="text-lg font-semibold text-primary">
                        {parseInt(data.prix).toLocaleString('fr-FR')} Ar
                      </span>
                      <span className="text-lg text-gray-500">({data.choix})</span>
                    </div>
                  </div>

                  {/* Dates */}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-6">
                    <div>
                      <span className="text-lg font-semibold text-gray-700">Date d'activation :</span>
                      <div className="mt-2 ml-5 inline-block border border-green-500 px-3 py-1 rounded-full text-green-600 font-medium bg-green-50">
                        {new Date(data.dateDebut).toLocaleDateString('fr-FR', {
                          day: 'numeric',
                          month: 'long',
                          year: 'numeric',
                        })}
                      </div>
                    </div>

                    <div>
                      <span className="text-lg font-semibold text-gray-700">Date limite :</span>
                      <div className="mt-2 ml-5 inline-block border border-red-500 px-3 py-1 rounded-full text-red-600 font-medium bg-red-50">
                        {new Date(data.dateFin).toLocaleDateString('fr-FR', {
                          day: 'numeric',
                          month: 'long',
                          year: 'numeric',
                        })}
                      </div>
                    </div>
                  </div>

                  {/* État */}
                  <div className="mt-6">
                    <div className="flex items-center space-x-4">
                      <span className="text-lg font-semibold text-gray-700">État :</span>
                      <span
                        className={`text-lg font-semibold ${
                          data.etat === 'Actif' ? 'text-green-500' : 'text-primary'
                        }`}
                      >
                        {data.etat}
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>

        </div>
      </div>
      
    </div>
  )
}

export default Dashboard

