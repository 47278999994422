import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { api } from '../../api';

const ChangePwd = () => {
  const { email } = useParams(); // Récupération de l'email depuis l'URL
  const navigate = useNavigate();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleChangePassword = async () => {
    setError('');
    setSuccess('');
    setLoading(true);

    // Vérifier si les champs sont remplis
    if (!password || !confirmPassword) {
      setError('Veuillez remplir tous les champs.');
      setLoading(false);
      return;
    }

    // Vérifier la longueur du mot de passe
    if (password.length < 6) {
      setError('Le mot de passe doit contenir au moins 6 caractères.');
      setLoading(false);
      return;
    }

    // Vérifier si les mots de passe correspondent
    if (password !== confirmPassword) {
      setError('Les mots de passe ne correspondent pas.');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(`${api}/api/changePassword`, {
        email,
        password,
        password_confirmation: confirmPassword
      });

      if (response.data.success) {
        setSuccess('Votre mot de passe a été changé avec succès.');
        
        // Redirection après 3 secondes
        setTimeout(() => {
          navigate('/Connexion');
        }, 3000);
      } else {
        setError(response.data.message || 'Une erreur est survenue.');
      }
    } catch (err) {
      setError('Impossible de modifier le mot de passe. Veuillez réessayer.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-6">
      <div className="max-w-md w-full bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold text-center text-gray-900 mb-2">Modification du mot de passe</h2>

        {error && <div className="text-red-500 text-center mb-3">{error}</div>}
        {success && <div className="text-green-500 text-center mb-3">{success}</div>}

        <div className="space-y-4">
          <div className='space-y-2'>
            <label className="font-semibold">Nouveau mot de passe</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500"
              placeholder="********"
            />
          </div>
          <div className='space-y-2'>
            <label className="font-semibold">Confirmer le mot de passe</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500"
              placeholder="********"
            />
          </div>
          <button
            type="button"
            onClick={handleChangePassword}
            disabled={loading}
            className={`w-full text-white bg-blue-600 hover:bg-blue-700 font-semibold py-2 px-4 rounded-md ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {loading ? 'Modification en cours...' : 'Changer le mot de passe'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangePwd;
