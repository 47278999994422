import React, { useEffect, useState } from 'react'
import SideBarKaonty from '../../../Components/SideBarKaonty'
import Header from '../../../Components/Admin/Header'
import img from '../../../Assets/photoUser.jpg'
import {EditOutlined} from '@ant-design/icons'
import { Empty } from 'antd';
import {Modal} from 'antd'
import axios from 'axios'
import { api } from '../../../api'
import { Button, message, Space } from 'antd';

const Entite = () => {

  const user_id = sessionStorage.getItem('user_id');
  const [open, setOpen] = useState(false);
  const [openMod, setOpenMod] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [confirmLoadingMod, setConfirmLoadingMod] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [selectedImageMod, setSelectedImageMod] = useState('');
  const [loading, setLoading] = useState(true);

  const [messageApi, contextHolder] = message.useMessage();
  const infoIncomplete = () => {
    messageApi.open({
      type: 'info',
      content: 'Veuillez remplir tous les champs s\'il vous plaît.',
    });
  };

  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Ajout des informations d\'entité réussie.',
    });
  };

  const successModification = () => {
    messageApi.open({
      type: 'success',
      content: 'Modification des informations d\'entité réussie.',
    });
  };

  const showModal = () => {
    setOpen(true);
  };

  const showModalMod = () => {
    setOpenMod(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    ajoutEntite();
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleOkMod = () => {
    setConfirmLoadingMod(true);
    modEntite()
    setTimeout(() => {
      setOpenMod(false);
      setConfirmLoadingMod(false);
    }, 2000);
  };

  // const handleOkMod = () => {
  //   setConfirmLoading(true);
  //   ajoutEntite();
  //   setTimeout(() => {
  //     setOpen(false);
  //     setConfirmLoading(false);
  //   }, 2000);
  // };

  const handleCancel = () => {
    // console.log('Clicked cancel button');
    setOpen(false);
  };

  const handleCancelMod = () => {
    // console.log('Clicked cancel button');
    setOpenMod(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEntiteInfo({
      ...entiteInfo,
      [name]: value,
    });
  
  }

  const handleInputChangeMod = (e) => {
    const { name, value } = e.target;
    setOldEntiteInfo({
      ...oldEntiteInfo,
      [name]: value,
    });
  
  }

  // Ajouter une entité
  const ajoutEntite = () => {
    if (entiteInfo.nom === '' || entiteInfo.description === '' || entiteInfo.dateCreation === '' || entiteInfo.email === '' || entiteInfo.contact === '' || entiteInfo.adresse === '' || entiteInfo.nif === '' || entiteInfo.stat === '' || entiteInfo.rcs === '')
    {
      infoIncomplete()
    }

    else{
      
      const formData = new FormData();
      formData.append('nom', entiteInfo.nom);
      formData.append('description', entiteInfo.description);
      formData.append('activite', entiteInfo.activite);
      formData.append('dateCreation', entiteInfo.dateCreation);
      formData.append('adresse', entiteInfo.adresse);
      formData.append('contact', entiteInfo.contact);
      formData.append('email', entiteInfo.email);
      formData.append('nif', entiteInfo.nif);
      formData.append('stat', entiteInfo.stat);
      formData.append('rcs', entiteInfo.rcs);
      formData.append('user_id', user_id);

      if (entiteInfo.photo) {
        formData.append('photo', entiteInfo.photo);
      }
      
      try {
        axios.post(api + '/api/ajoutEntite', formData)
            .then(res => {
                getEntite();

                success()
                setEntiteInfo({
                  ...entiteInfo,
                  nom: '', description: '', activite:'', dateCreation:'', adresse:'', contact:'', email:'', nif:'', stat:'', rcs:''
                });
                
            })
            .catch(err => {
                // Gérer les erreurs de la requête
                if (err.response) {
                    // Le serveur a répondu avec un code de statut différent de 2xx
                    console.error('Erreur réponse serveur:', err.response.data);
                } else if (err.request) {
                    // La requête a été faite, mais aucune réponse reçue
                    console.error('Aucune réponse reçue:', err.request);
                } else {
                    // Autre erreur lors de la configuration de la requête
                    console.error('Erreur lors de la requête:', err.message);
                }
            });
      } catch (error) {
          // Gérer les erreurs inattendues dans le bloc try
          console.error('Erreur inattendue:', error);
      }

    }
  } 

  // Modification des info d'entité
  const modEntite = () => {
    if (oldEntiteInfo.nom === '' || oldEntiteInfo.description === '' || oldEntiteInfo.dateCreation === '' || oldEntiteInfo.email === '' || oldEntiteInfo.contact === '' || oldEntiteInfo.adresse === '' || oldEntiteInfo.nif === '' || oldEntiteInfo.stat === '' || oldEntiteInfo.rcs === '')
    {
      infoIncomplete()
    }

    else{
      
      const formData = new FormData();
      formData.append('nom', oldEntiteInfo.nom);
      formData.append('description', oldEntiteInfo.description);
      formData.append('activite', oldEntiteInfo.activite);
      formData.append('dateCreation', oldEntiteInfo.dateCreation);
      formData.append('adresse', oldEntiteInfo.adresse);
      formData.append('contact', oldEntiteInfo.contact);
      formData.append('email', oldEntiteInfo.email);
      formData.append('nif', oldEntiteInfo.nif);
      formData.append('stat', oldEntiteInfo.stat);
      formData.append('rcs', oldEntiteInfo.rcs);
      formData.append('user_id', user_id);

      if (oldEntiteInfo.photo) {
        formData.append('photo', oldEntiteInfo.photo);
      }
      
      try {
        axios.post(api + '/api/modEntite/' + oldEntiteInfo.id, formData)
            .then(res => {
                getEntite();

                successModification()
                
            })
            .catch(err => {
                // Gérer les erreurs de la requête
                if (err.response) {
                    // Le serveur a répondu avec un code de statut différent de 2xx
                    console.error('Erreur réponse serveur:', err.response.data);
                } else if (err.request) {
                    // La requête a été faite, mais aucune réponse reçue
                    console.error('Aucune réponse reçue:', err.request);
                } else {
                    // Autre erreur lors de la configuration de la requête
                    console.error('Erreur lors de la requête:', err.message);
                }
            });
      } catch (error) {
          // Gérer les erreurs inattendues dans le bloc try
          console.error('Erreur inattendue:', error);
      }

    }
  } 

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file); // Créer une URL pour l'image
      setSelectedImage(imageUrl); // Mettre à jour l'image sélectionnée
      // console.log(file)
      // setPhoto(file)
      setEntiteInfo({
        ...entiteInfo,
        photo: file,
      })
    }
  };

  const handleFileChangeMod = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file); // Créer une URL pour l'image
      setSelectedImageMod(imageUrl); // Mettre à jour l'image sélectionnée
      // console.log(file)
      // setPhoto(file)
      setOldEntiteInfo({
        ...oldEntiteInfo,
        photo: file,
      })
    }
  };

  // const [oldEntiteInfo, setOldEntiteInfo] = useState({
  //   photo: '',
  //   nom: '',
  //   description: '',
  //   activite: '',
  //   adresse: '',
  //   dateCreation: '',
  //   contact: '',
  //   email: '',
  //   nif: '',
  //   stat: '',
  //   rcs: ''
  // })

  const [oldEntiteInfo, setOldEntiteInfo] = useState([])

  const [entiteInfo, setEntiteInfo] = useState({
    photo: '',
    nom: '',
    description: '',
    activite: '',
    adresse: '',
    dateCreation: '',
    contact: '',
    email: '',
    nif: '',
    stat: '',
    rcs: ''
  })

  const [data, setData] = useState([]);

  // L'information sur l'entité d'un utilisateur s'il existe
  const getEntite = async () => {
    try {
      const response = await axios.get(api + `/api/getEntite/${user_id}`);
      setOldEntiteInfo(response.data[0]);
      setData(response.data)
      console.log(response.data);
      setLoading(false);

    } catch (error) {
      console.error('Error fetching entity:', error);
    }
  };

  useEffect(() => {
    getEntite();
  }, []);

  return (
    <div className='bg flex flex-row' style={{ height: '100vh' }}>
        {contextHolder}
      <div>
        <SideBarKaonty />
      </div>
      <div className='bg w-full' style={{ background: '#F9F9FC' }}>
        <div>
          <Header titre={'Entité'}/>
        </div>
        <div className='pl-8 pr-7 py-4'>

        <div className='b border-2 mt-10 p-7 rounded'>
            
        {loading ? (
                    // Spinner de chargement avec TailwindCSS
              <>
                    <div className="flex justify-center items-center">
                      <div className="w-12 h-12 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
                    </div>
              </>    

              ) : (

              <>
                {data.length === 0 ? 

                <div>
                  <div className='flex justify-between'>
                    <div>
                      
                    </div>
                    <div>
                      <button className='px-4 py-1 font-semibold bg-white text-primary duration-300 border-primary rounded border-2' onClick={showModal}>Ajouter les informations</button>
                    </div>
                  </div>
                  <div className='flex flex-col items-center justify-center h-full p-4'>
                      <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={<span className='text-lg'>Vous n'avez pas encore ajouter des informations sur votre entité. <br /></span>}
                        />
                      </div>
                </div>

                :
                <>

                    <div className='flex justify-between'>
                      <div className='flex space-x-4'>
                        <img src={api + '/storage/' + oldEntiteInfo?.photo} alt="" className='w w-16 h-16 object-cover rounded-full'/>
                        <div className='j flex justify-center items-center'>
                          <p className='f font-semibold text-xl'>{oldEntiteInfo?.nom}</p>
                        </div>
                      </div>
                      <div>
                        <button className='px-4 py-1 font-semibold bg-white text-primary duration-300 border-primary rounded-lg border-2' onClick={showModalMod} >Modifier <EditOutlined /></button>
                      </div>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2 py-4 mt-5">
                      
                      <div className="py-2 text-left">
                        <p className="font-semibold">Description</p>
                        <p>{oldEntiteInfo?.description}</p>
                      </div>
                      
                      <div className="py-2 text-left">
                        <p className="font-semibold">Activité</p>
                        <p>{oldEntiteInfo?.activite}</p>
                      </div>

                      <div className="py-2 text-left">
                        <p className="font-semibold">Adresse</p>
                        <p>{oldEntiteInfo?.adresse}</p>
                      </div>

                      <div className="py-2 text-left">
                        <p className="font-semibold">Date de création</p>
                        <p>{new Date(oldEntiteInfo?.dateCreation).toLocaleDateString()}</p>
                      </div>

                      <div className="py-2 text-left">
                        <p className="font-semibold">Contact</p>
                        <p>{oldEntiteInfo?.contact}</p>
                      </div>

                      <div className="py-2 text-left">
                        <p className="font-semibold">E-mail</p>
                        <p>{oldEntiteInfo?.email}</p>
                      </div>

                      <div className="py-2 text-left">
                        <p className="font-semibold">NIF</p>
                        <p>{oldEntiteInfo?.nif}</p>
                      </div>

                      <div className="py-2 text-left">
                        <p className="font-semibold">Stat</p>
                        <p>{oldEntiteInfo?.stat}</p>
                      </div>

                      <div className="py-2 text-left">
                        <p className="font-semibold">RCS</p>
                        <p>{oldEntiteInfo?.rcs}</p>
                      </div>

                    </div>
                </>
          }</>)}

        </div>

        </div>
      </div>

      {/* Modal d'ajout des info sur l'entité */}
      <Modal
        title= {(<div className='text-center'><span className='text-lg'>Ajout d'entité</span></div>)}
        width={800} open={open} onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel} footer={(
          <div className="flex justify-center space-x-12">
            <button 
              onClick={handleCancel} 
              className="px-6 py-2 bg-gray-200 text-black rounded hover:bg-gray-300"
            >
              Annuler
            </button>
            <button 
              onClick={handleOk} 
              className="px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              disabled={confirmLoading}
            >
              {confirmLoading ? 'En cours...' : 'Enregistrer'}
            </button>
          </div>
        )}
      >
            <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-3 gap-x-8 py-1 mt-1'>
                  <div className="flex flex-col items-center justify-center">
                        {/* Champ de type file caché */}
                        <input
                          id="fileInput"
                          type="file"
                          className="hidden" 
                          onChange={handleFileChange} 
                        />

                        {/* Label circulaire pour déclencher l'upload */}
                        <label
                          htmlFor="fileInput"
                          className="cursor-pointer flex items-center justify-center w-20 h-20 bg-gray-200 rounded-full overflow-hidden border border-dashed border-gray-400 hover:bg-gray-300"
                        >
                          {selectedImage ? (
                            <img
                              src={selectedImage}
                              alt="Selected"
                              className="w-full h-full object-cover"
                            />
                          ) : (
                            <span className="text-gray-500 text-sm text-center">Sélectionner une image</span>
                          )}
                        </label>
                  </div>
                
                <div className='grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-3 gap-x-8 py-1 mt-1'>

                    <div className='space-y-2'>
                        <label htmlFor="email" className="font-semibold">Nom</label>
                        <input id="nom" name="nom"  type="text" required value={entiteInfo.nom} onChange={handleInputChange} className="appearance-none relative block w-full px-2 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Nom d'entité"/>
                    </div>     
                    <div className='space-y-2'>
                        <label htmlFor="email" className="font-semibold">Date de création</label>
                        <input id="dateCreation" name="dateCreation" type="date"  required  value={entiteInfo.dateCreation}  onChange={handleInputChange}  className="appearance-none relative block w-full px-2 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"  placeholder="Date de création"/>
                    </div> 
                    <div className='space-y-2'>
                        <label htmlFor="email" className="font-semibold">Description</label>
                        <textarea id="description" name="description" type="text" required value={entiteInfo.description} onChange={handleInputChange} className="appearance-none relative block h-15 w-full px-2 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Description"
                        />
                    </div> 
                    <div className='space-y-2'>
                        <label htmlFor="email" className="font-semibold">Activité</label>
                        <textarea id="activite" name="activite" type="text" required value={entiteInfo.activite} onChange={handleInputChange} className="appearance-none relative h-15 block w-full px-2 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Activité"
                        />
                    </div> 
                    <div className='space-y-2'>
                        <label htmlFor="email" className="font-semibold">Email</label>
                        <input id="email" name="email" type="email" required value={entiteInfo.email} onChange={handleInputChange} className="appearance-none relative block w-full px-2 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Email"
                        />
                    </div> 
                    <div className='space-y-2'>
                        <label htmlFor="email" className="font-semibold">Contact</label>
                        <input id="contact" name="contact" type="text" required value={entiteInfo.contact} onChange={handleInputChange} className="appearance-none relative block w-full px-2 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Contact"
                        />
                    </div> 
                    <div className='space-y-2'>
                        <label htmlFor="email" className="font-semibold">Adresse</label>
                        <input id="adresse" name="adresse" type="text" required value={entiteInfo.adresse} onChange={handleInputChange} className="appearance-none relative block w-full px-2 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Adresse"
                        />
                    </div> 
                    <div className='space-y-2'>
                        <label htmlFor="email" className="font-semibold">NIF</label>
                        <input id="nif" name="nif" type="text" required value={entiteInfo.nif} onChange={handleInputChange} className="appearance-none relative block w-full px-2 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="NIF"
                        />
                    </div> 
                    <div className='space-y-2'>
                        <label htmlFor="email" className="font-semibold">STAT</label>
                        <input id="stat" name="stat" type="text" required value={entiteInfo.stat} onChange={handleInputChange} className="appearance-none relative block w-full px-2 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="STAT"
                        />
                    </div> 
                    <div className='space-y-2'>
                        <label htmlFor="email" className="font-semibold">RCS</label>
                        <input id="rcs" name="rcs" type="text" required value={entiteInfo.rcs} onChange={handleInputChange} className="appearance-none relative block w-full px-2 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="RCS"
                        />
                    </div> 

                </div>
                
            </div>
      </Modal>

      {/* Modal de modification des info d'entité */}
      <Modal
        title= {(<div className='text-center'><span className='text-lg'>Modification d'entité</span></div>)}
        width={800} open={openMod} onOk={handleOkMod}
        confirmLoading={confirmLoadingMod}
        onCancel={handleCancelMod} footer={(
          <div className="flex justify-center space-x-12">
            <button 
              onClick={handleCancelMod} 
              className="px-6 py-2 bg-gray-200 text-black rounded hover:bg-gray-300"
            >
              Annuler
            </button>
            <button 
              onClick={handleOkMod} 
              className="px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              disabled={confirmLoadingMod}
            >
              {confirmLoadingMod ? 'En cours...' : 'Enregistrer'}
            </button>
          </div>
        )}
      >
            <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-3 gap-x-8 py-1 mt-1'>
                  <div className="flex flex-col items-center justify-center">
                        {/* Champ de type file caché */}
                        <input
                          id="fileInput"
                          type="file"
                          className="hidden" 
                          onChange={handleFileChangeMod} 
                        />

                        {/* Label circulaire pour déclencher l'upload */}
                        <label
                          htmlFor="fileInput"
                          className="cursor-pointer flex items-center justify-center w-20 h-20 bg-gray-200 rounded-full overflow-hidden border border-dashed border-gray-400 hover:bg-gray-300"
                        >
                          {selectedImageMod ? (
                            <img
                              // src={api + '/storage/' + oldEntiteInfo.photo}
                              src={selectedImageMod}
                              alt="Selected"
                              className="w-full h-full object-cover"
                            />
                          ) : (
                            <span className="text-gray-500 text-sm text-center">Sélectionner une image</span>
                          )}
                        </label>
                  </div>
                
                <div className='grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-3 gap-x-8 py-1 mt-1'>

                    <div className='space-y-2'>
                        <label htmlFor="email" className="font-semibold">Nom</label>
                        <input id="nom" name="nom"  type="text" required value={oldEntiteInfo?.nom} onChange={handleInputChangeMod} className="appearance-none relative block w-full px-2 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Nom d'entité"/>
                    </div>     
                    <div className='space-y-2'>
                        <label htmlFor="email" className="font-semibold">Date de création</label>
                        <input id="dateCreation" name="dateCreation" type="date"  required  value={oldEntiteInfo?.dateCreation}  onChange={handleInputChangeMod}  className="appearance-none relative block w-full px-2 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"  placeholder="Date de création"/>
                    </div> 
                    <div className='space-y-2'>
                        <label htmlFor="email" className="font-semibold">Description</label>
                        <textarea id="description" name="description" type="text" required value={oldEntiteInfo?.description} onChange={handleInputChangeMod} className="appearance-none relative block h-15 w-full px-2 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Description"
                        />
                    </div> 
                    <div className='space-y-2'>
                        <label htmlFor="email" className="font-semibold">Activité</label>
                        <textarea id="activite" name="activite" type="text" required value={oldEntiteInfo?.activite} onChange={handleInputChangeMod} className="appearance-none relative h-15 block w-full px-2 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Activité"
                        />
                    </div> 
                    <div className='space-y-2'>
                        <label htmlFor="email" className="font-semibold">Email</label>
                        <input id="email" name="email" type="email" required value={oldEntiteInfo?.email} onChange={handleInputChangeMod} className="appearance-none relative block w-full px-2 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Email"
                        />
                    </div> 
                    <div className='space-y-2'>
                        <label htmlFor="email" className="font-semibold">Contact</label>
                        <input id="contact" name="contact" type="text" required value={oldEntiteInfo?.contact} onChange={handleInputChangeMod} className="appearance-none relative block w-full px-2 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Contact"
                        />
                    </div> 
                    <div className='space-y-2'>
                        <label htmlFor="email" className="font-semibold">Adresse</label>
                        <input id="adresse" name="adresse" type="text" required value={oldEntiteInfo?.adresse} onChange={handleInputChangeMod} className="appearance-none relative block w-full px-2 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Adresse"
                        />
                    </div> 
                    <div className='space-y-2'>
                        <label htmlFor="email" className="font-semibold">NIF</label>
                        <input id="nif" name="nif" type="text" required value={oldEntiteInfo?.nif} onChange={handleInputChangeMod} className="appearance-none relative block w-full px-2 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="NIF"
                        />
                    </div> 
                    <div className='space-y-2'>
                        <label htmlFor="email" className="font-semibold">STAT</label>
                        <input id="stat" name="stat" type="text" required value={oldEntiteInfo?.stat} onChange={handleInputChangeMod} className="appearance-none relative block w-full px-2 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="STAT"
                        />
                    </div> 
                    <div className='space-y-2'>
                        <label htmlFor="email" className="font-semibold">RCS</label>
                        <input id="rcs" name="rcs" type="text" required value={oldEntiteInfo?.rcs} onChange={handleInputChangeMod} className="appearance-none relative block w-full px-2 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="RCS"
                        />
                    </div> 

                </div>
                
            </div>
      </Modal>
      
    </div>
  )
}

export default Entite

