import React from 'react'
import {motion} from "framer-motion"
import {fadeIn} from '../variant'

const Apropos = () => {
  return (
    <>
      <motion.div
      variants={fadeIn("up", 0.2)}
      initial="hidden"
      whileInView={"show"}
      viewport={{ once: false, amount: 0.7 }}
      className="md:text-center md:px-24 max-sm:px-4"
      id="Apropos"
    >
      <h1 className="text-2xl font-bold mb-6 max-sm:text-center max-sm:mt-5">
        <span className="border-primary border-b-2 py-1">À propos</span>
      </h1>

      <div className="space-y-5 text-lg text-gray-700 leading-relaxed lg:px-40 text-center">
        <p>
          Notre application de gestion de comptabilité a été conçue par une équipe de professionnels passionnés par l’innovation financière, avec l’objectif de rendre la gestion financière des petites et moyennes entreprises plus simple et efficace. Grâce à une <span>interface intuitive</span> et des <span>fonctionnalités essentielles</span>, elle permet aux entrepreneurs de suivre leurs revenus, leurs dépenses et leur trésorerie en toute simplicité. 
        </p>

        <p>
          Nous avons mis un point d’honneur à éliminer la complexité souvent associée aux logiciels de comptabilité traditionnels, afin d’offrir un <span>outil accessible</span> à tous, même sans expertise comptable. En <span>automatisant certaines tâches</span> et en <span>centralisant les informations financières</span>, notre solution aide les entrepreneurs à gagner du temps et à se concentrer sur le développement de leur activité.
        </p>
      </div>
    </motion.div>

    </>
  )
}

export default Apropos