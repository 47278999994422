import React, { useEffect, useState } from 'react'
import SideBarKaonty from '../../../Components/SideBarKaonty'
import Header from '../../../Components/Admin/Header'
import { useNavigate, useParams } from 'react-router-dom'
import {LeftCircleOutlined} from '@ant-design/icons'
import img from '../../../Assets/photoUser.jpg'
import { api } from '../../../api'
import axios from 'axios'
import userDefaut from '../../../Assets/user2.png'
import ModalImage from '../../../Components/ModalImage'

const DetailUtilisateur = () => {

  const {id} = useParams()
  const navigate = useNavigate()

  const revenir = () => {
    navigate('/admin/utilisateur')
  }
  const [loading, setLoading] = useState(true);

  const [userInfo, setUserInfo] = useState({
    photo: '',
    nom: '',
    prenom: '',
    contact: '',
    adresse: '',
    email: '',
  })

  useEffect(() => {
    getInfoUser();
  }, []);

  // Information d'un utilisateur
  // Tous les codes journals perso
  const getInfoUser = async () => {
    try {
      const response = await axios.get(api + `/api/infoUser/${id}`);
      setUserInfo(response.data[0]);
      // console.log(response.data)
      setLoading(false);

    } catch (error) {
      console.error('Error fetching info user:', error);
    }
  };

  const [isModalVisibleImg, setIsModalVisibleImg] = useState(false);
  const [imageToShow, setImageToShow] = useState("");

  const handleImageClick = (imageUrl) => {
    setImageToShow(imageUrl);
    setIsModalVisibleImg(true);
  };

  const closeModal = () => {
    setIsModalVisibleImg(false);
    setImageToShow("");
  };


  return (
    <div className='bg flex flex-row' style={{ height: '100vh' }}>
      <div>
        <SideBarKaonty />
      </div>
      <div className='bg w-full' style={{ background: '#F9F9FC' }}>
        <div>
          <Header titre={'Utilisateur'}/>
        </div>

        <div className='pl-8 pr-7 py-6'>
          <div className='flex justify-between'>
            <div className='flex space-x-3'>
              <span onClick={revenir}><LeftCircleOutlined style={{ fontSize: '25px', color: '#0071BD' }} className='c cursor-pointer'/></span>
                <p className='f font-semibold'>Détails d'un utilisateur :</p>
            </div>
          </div>

          {/* Div en bas */}
          <div className='b border-2 mt-10 p-7 rounded-2xl'>
          {loading ? (
              // Spinner de chargement avec TailwindCSS
              <div className="flex justify-center items-center h-32">
                <div className="w-10 h-10 border-4 border-blue-500 border-dashed rounded-full animate-spin"></div>
              </div>
            ) : (
            <div>
              <div className='flex space-x-4'>
                <img src={ userInfo.photo ? api+'/storage/'+userInfo.photo : userDefaut} alt="" onClick={() => {handleImageClick(userInfo.photo)}} className='w w-20 object-cover rounded-full cursor-pointer'/>
                <div className='j flex justify-center items-center'>
                  <p className='f font-semibold text-xl'>{userInfo.nom} {userInfo.prenom}</p>
                </div>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2 py-4 mt-5">
                
                  <div className="p-2 text-left">
                    <p className="font-semibold">Nom et prénom(s)</p>
                    <p>{userInfo.nom} {userInfo.prenom}</p>
                  </div>
                  <div className="p-2 text-left">
                    <p className="font-semibold">E-mail</p>
                    <p>{userInfo.email}</p>
                  </div>
                  <div className="p-2 text-left">
                    <p className="font-semibold">Contact</p>
                    <p>{userInfo.contact}</p>
                  </div>
                  <div className="p-2 text-left">
                    <p className="font-semibold">Adresse</p>
                    <p>{userInfo.adresse}</p>
                  </div>
              
              </div>
            </div>
            )}

          </div>

        </div>

      </div>

      {/* Modal d'affichage */}
      {isModalVisibleImg && (
              <ModalImage
                imageUrl={imageToShow}
                visible={isModalVisibleImg}
                onClose={closeModal}
              />
            )}
      
    </div>
  );
}


export default DetailUtilisateur

