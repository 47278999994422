import React, { useState } from 'react';
import { Modal, Form, Input, Button, message } from 'antd';
import { api } from '../api';
import axios from 'axios';

const PasswordModal = ({ isVisible, onClose, onSubmit }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const user_id = sessionStorage.getItem('user_id');
  const token = sessionStorage.getItem('token');


  // const handleSubmit = async () => {
  //   try {
  //     const values = await form.validateFields();
  //     setLoading(true);

  //     // Simulez l'envoi de données au backend
  //     setTimeout(() => {
  //       onSubmit(values);
  //       message.success("Mot de passe modifié avec succès !");
  //       form.resetFields();
  //       setLoading(false);
  //       onClose();
  //     }, 1000);
  //   } catch (err) {
  //     console.error("Erreur dans la soumission du formulaire :", err);
  //   }
  // };

  const handleSubmit = async () => {
  try {
    const values = await form.validateFields();
    setLoading(true);

    // Appel à l'API pour modifier le mot de passe
    try {
      const response = await axios.post(api + '/api/modPassword', {
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        message.success(response.data.message || "Mot de passe modifié avec succès !");
        form.resetFields();
        onClose();
      } else {
        message.error(response.data.message || "Une erreur est survenue !");
      }
    } catch (apiError) {
      console.error("Erreur API:", apiError);
      message.error(apiError.response?.data?.message || "Impossible de modifier le mot de passe !");
    }

    setLoading(false);
  } catch (validationError) {
    console.error("Erreur dans la soumission du formulaire :", validationError);
  }
};


  return (
    <Modal
      title="Modifier le mot de passe"
      visible={isVisible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Annuler
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
          Enregistrer
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" name="passwordForm">
        <Form.Item
          name="currentPassword"
          label="Mot de passe actuel"
          rules={[
            { required: true, message: "Veuillez entrer votre mot de passe actuel !" },
          ]}
        >
          <Input.Password placeholder="Mot de passe actuel" />
        </Form.Item>

        <Form.Item
          name="newPassword"
          label="Nouveau mot de passe"
          rules={[
            { required: true, message: "Veuillez entrer un nouveau mot de passe !" },
            { min: 8, message: "Le mot de passe doit contenir au moins 8 caractères !" },
            {
              pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/,
              message:
                "Le mot de passe doit contenir au moins une majuscule, une minuscule, un chiffre et un symbole (@#$%^&+=!)",
            },
          ]}
        >
          <Input.Password placeholder="Nouveau mot de passe" />
        </Form.Item>


        <Form.Item
          name="confirmPassword"
          label="Confirmer le nouveau mot de passe"
          dependencies={['newPassword']}
          rules={[
            { required: true, message: "Veuillez confirmer votre nouveau mot de passe !" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Les mots de passe ne correspondent pas !"));
              },
            }),
          ]}
        >
          <Input.Password placeholder="Confirmer le nouveau mot de passe" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PasswordModal;
