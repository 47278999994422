import React, { useEffect, useState } from 'react'
import SideBarKaonty from '../../../Components/SideBarKaonty'
import Header from '../../../Components/Admin/Header'
import { Dropdown, Space } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import axios from 'axios';
import { api } from '../../../api';
import { useNavigate } from 'react-router-dom';

const Historique = () => {
  const user_id = sessionStorage.getItem('user_id');
  const navigate = useNavigate()

  // const data = [
  //   { id: 1, dateDebut: "15 Septembre 2024", dateFin: "15 Septembre 2025", type: "Standard", etat: 'En cours' },
  //   { id: 2, dateDebut: "15 Septembre 2023", dateFin: "15 Septembre 2024", type: "Standard", etat: 'Expiré' },
  //   { id: 3, dateDebut: "15 Septembre 2024", dateFin: "15 Septembre 2025", type: "Standard", etat: 'Expiré' },
  // ];

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true);

  // Tous les demandes
  const getAllAbonnements = async () => {
    try {
      const response = await axios.get(api + `/api/allAbonnementUser/${user_id}`);
      setData(response.data);
      // setLoading(false);

    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  // Fonction pour rediriger vers la page des détails
  const  detailDemande = (id) => {
    navigate(`/admin/demande/${id}`);
  };

  const items = (id) => [
    {
      label: <p className='f font-semibold hover:text-primary' onClick={() => detailDemande(id)}>Voir demande</p>,
      key: '0',
    }
  ];

  useEffect(() => {
    getAllAbonnements();
  }, []);

  return (
    <div className='bg flex flex-row' style={{ height: '100vh' }}>
      <div>
        <SideBarKaonty />
      </div>
      <div className='bg w-full' style={{ background: '#F9F9FC' }}>
        <div>
          <Header titre={'Historique'}/>
        </div>

        <div className='pl-8 pr-7 py-6'>
          <div>
            <div>
              <span className='text-lg font-semibold'>Historique d'abonnement</span>
            </div>
          </div>

          {/* Tableau */}
          <div className="overflow-x-auto mt-5 rounded shadow-lg">
            <table className="min-w-full bg-white border border-gray-200 rounded">
              <thead>
                <tr className="bg-gray-400 text-white border-b">
                  <th className="py-3 px-4 text-left text-sm font-semibold">Date début</th>
                  <th className="py-3 px-4 text-left text-sm font-semibold">Date d'expiration</th>
                  <th className="py-3 px-4 text-left text-sm font-semibold">Type</th>
                  <th className="py-3 px-4 text-left text-sm font-semibold">Choix</th>
                  <th className="py-3 px-4 text-left text-sm font-semibold">Prix (Ar)</th>
                  <th className="py-3 px-4 text-left text-sm font-semibold">État</th>
                  <th className="py-3 px-4 text-center text-sm font-semibold">Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map((abonnement, index) => (
                  <tr
                    key={abonnement.id}
                    className={`border-b ${
                      index % 2 === 0 ? "bg-gray-50" : "bg-white"
                    } hover:bg-gray-100 transition-colors`}
                  >
                    <td className="py-3 px-4 text-sm text-gray-600">
                      {new Date(abonnement.dateDebut).toLocaleDateString("fr-FR", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })}
                    </td>
                    <td className="py-3 px-4 text-sm text-gray-600">
                      {new Date(abonnement.dateFin).toLocaleDateString("fr-FR", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })}
                    </td>
                    <td className="py-3 px-4 text-sm text-gray-600">{abonnement.type}</td>
                    <td className="py-3 px-4 text-sm text-gray-600">{abonnement.choix}</td>
                    <td className="py-3 px-4 text-sm text-gray-600">
                      {parseInt(abonnement.prix).toLocaleString("fr-FR")}
                    </td>
                    <td className="py-3 px-4">
                      <span
                        className={`inline-block px-3 py-1 rounded-full text-xs font-semibold ${
                          abonnement.etat === "EN COURS"
                            ? "bg-blue-100 text-blue-600 border border-blue-500"
                            : abonnement.etat === "EXPIRE"
                            ? "bg-red-100 text-red-600 border border-red-500"
                            : "bg-gray-100 text-gray-600 border border-gray-400"
                        }`}
                      >
                        {abonnement.etat}
                      </span>
                    </td>
                    <td className="py-3 px-4 text-center">
                      <Dropdown
                        menu={{
                          items: items(abonnement.demande_id),
                        }}
                        trigger={["click"]}
                      >
                        <a
                          onClick={(e) => e.preventDefault()}
                          className="text-gray-700 hover:text-blue-600 flex items-center justify-center"
                        >
                          <EllipsisOutlined
                            style={{ fontSize: "20px" }}
                            className="cursor-pointer"
                          />
                        </a>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>


        </div>
      </div>
      
    </div>
  )
}

export default Historique

