import React, { useEffect, useState } from 'react'
import SideBar from '../../../Components/SuperAdmin/SideBar'
import Header from '../../../Components/SuperAdmin/Header'
import {EditOutlined, DeleteOutlined, ExclamationCircleFilled,FilterOutlined} from '@ant-design/icons'
import {Modal} from 'antd'
import { api } from '../../../api'
import { Button, message, Space } from 'antd';
import axios from 'axios'
import { format } from "date-fns";
import fr from "date-fns/locale/fr";

const Historique = () => {

  const user_id = sessionStorage.getItem('user_id');
  const [loading, setLoading] = useState(true);

  // const data = [
  //   { id: 1, date: '22 Septembre 2024', action: 'Ajout', detail: 'Ajout d\'un plan comptable', utilisateur: 'RANDRIA Assil'},
  //   { id: 1, date: '22 Septembre 2024', action: 'Ajout', detail: 'Ajout d\'un plan comptable', utilisateur: 'RANDRIA Assil'},
  //   { id: 1, date: '23 Septembre 2024', action: 'Modification', detail: 'modification d\'un plan comptable', utilisateur: 'ZAFY Lucio'},
  //   { id: 1, date: '23 Septembre 2024', action: 'Ajout', detail: 'Ajout d\'un plan comptable', utilisateur: 'RANDRIA Assil'},
  //   { id: 1, date: '24 Septembre 2024', action: 'Ajout', detail: 'Ajout d\'un journal de saisie', utilisateur: 'ZAFY Lucio'},
  // ];

  const [data, setData] = useState([])

  // Tous les historiques
  // const getAllHistorique = async () => {
  //   try {
  //     const response = await axios.get(api + '/api/allHistorique');
  //     setData(response.data);
  //     setLoading(false);

  //   } catch (error) {
  //     console.error('Error fetching code journal:', error);
  //   }
  // };

  const getAllHistorique = async () => {
    try {
      const response = await axios.get(api + '/api/allHistorique');
      setData(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des historiques:', error);
    } finally {
      setLoading(false); // Toujours arrêter le chargement
    }
  };
  


  useEffect(() => {
    getAllHistorique();
  }, []);

  const [filterText, setFilterText] = useState("");

  const filteredData = data.filter(historique =>
    historique.type?.toLowerCase().includes(filterText.toLowerCase())
  );

  // Ajout code journal
  // const handleOk = () => {
  //   setConfirmLoading(true);

  //   const formData = new FormData();
  //   formData.append('libelle', codeJournale.libelle);
  //   formData.append('abreviation', codeJournale.abreviation);
  //   formData.append('user_id', user_id)
  //   formData.append('etat', 'global')
    
  //   try {
  //     axios.post(api + '/api/ajoutCodeJournal', formData)
  //         .then(res => {
  //             success();
  //             // Gérer la réponse en cas de succès
  //             console.log('Ajout code journal réussie:', res.data);
  //             getAllCodeJournal()
  //             setCodeJournale({
  //               ...codeJournale,
  //               libelle: '', abreviation: ''  
  //             });
              
  //         })
  //         .catch(err => {
  //             // Gérer les erreurs de la requête
  //             if (err.response) {
  //                 // Le serveur a répondu avec un code de statut différent de 2xx
  //                 console.error('Erreur réponse serveur:', err.response.data);
  //             } else if (err.request) {
  //                 // La requête a été faite, mais aucune réponse reçue
  //                 console.error('Aucune réponse reçue:', err.request);
  //             } else {
  //                 // Autre erreur lors de la configuration de la requête
  //                 console.error('Erreur lors de la requête:', err.message);
  //             }
  //         });
  //   } catch (error) {
  //       // Gérer les erreurs inattendues dans le bloc try
  //       console.error('Erreur inattendue:', error);
  //   }

  //   setTimeout(() => {
  //     setOpen(false);
  //     setConfirmLoading(false);
  //   }, 2000);
  // };

  return (
    <div className='bg flex flex-row' style={{ height: '100vh' }}>
      <div>
        <SideBar />
      </div>
      <div className='bg w-full' style={{ background: '#F9F9FC' }}>
        <div>
          <Header titre={'Historique'}/>
        </div>
        <div className='pl-8 pr-7 py-4'>
        <div className='flex justify-between'>
        <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-5 space-y-3 sm:space-y-0">
            {/* Titre avec icône */}
            {/* <div className="flex items-center space-x-2">
              <span className="font-semibold text-lg text-gray-700">Filtre</span>
              <FilterOutlined className="text-blue-500 text-xl" />
            </div> */}
            
            {/* Champ de recherche */}
            <div className="relative">
              <span className="absolute inset-y-0 left-3 flex items-center text-gray-400">
                <FilterOutlined />
              </span>
              <input
                type="text"
                placeholder="Filtrer par action"
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                className="w-full sm:w-72 pl-10 pr-4 py-2 border border-gray-300 rounded-lg text-gray-700 text-sm focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-blue-400"
              />

            </div>

          </div>


              
          </div>
          {/* Table */}
          <div className="overflow-x-auto mt-5 rounded-xl shadow-lg bg-gray-50">
              {loading ? (
                // Spinner de chargement avec TailwindCSS
                <div className="flex justify-center items-center">
                      <div className="w-12 h-12 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
                    </div>
              ) : (
                <table className="min-w-full bg-white border border-gray-600 rounded-xl overflow-hidden">
                  <thead>
                    <tr className="bg-blue-100 text-gray-800">
                      <th className="py-3 px-4 text-left text-sm font-semibold">Date</th>
                      <th className="py-3 px-4 text-left text-sm font-semibold">Action</th>
                      <th className="py-3 px-4 text-left text-sm font-semibold">Détail</th>
                      <th className="py-3 px-4 text-left text-sm font-semibold">Utilisateur</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.length > 0 ? (
                      filteredData.map((historique, index) => (
                        <tr
                          key={historique.id}
                          className={`border-b ${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'} hover:bg-blue-50 transition`}
                        >
                          <td className="py-3 px-4 text-sm">
                            {format(new Date(historique.created_at), "dd/MM/yyyy HH:mm", { locale: fr })}
                          </td>
                          <td className="py-3 px-4 text-sm">{historique.type || '-'}</td>
                          <td className="py-3 px-4 text-sm">{historique.detail || 'Aucun détail disponible'}</td>
                          <td className="py-3 px-4 text-sm">
                            {historique.user?.nom} {historique.user?.prenom || '-'}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center py-6 text-gray-500">
                          Aucun historique trouvé.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>


        </div>
      </div>

      {/* Modal d'ajout */}
      {/* <Modal title="Ajout d'un code journal" open={open} onOk={handleOk} confirmLoading={confirmLoading} onCancel={handleCancel} footer={(
              <div className="flex justify-center space-x-4">
                <button 
                  onClick={handleCancel} 
                  className="px-6 py-2 bg-gray-200 text-black rounded hover:bg-gray-300"
                >
                  Annuler
                </button>
                <button
                  onClick={handleOk} 
                  className="px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                  disabled={confirmLoading}
                >
                  {confirmLoading ? 'En cours...' : 'Enregistrer'}
                </button>
              </div>
            )}
          >
                <div className='space-y-2 mt-8 mb-4'>
                  <label className="font-semibold">Libellé</label>
                  <input
                    id="libelle"
                    name="libelle"
                    type="text"
                    onChange={handleInputChange}
                    required
                    value={codeJournale.libelle}
                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Entrez la libellé"
                  />
                </div>

                <div className='space-y-2 mt-3 mb-8'>
                  <label className="font-semibold">Abréviation</label>
                  <input
                    id="abreviation"
                    name="abreviation"
                    type="text"
                    onChange={handleInputChange}
                    required
                    value={codeJournale.abreviation}
                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Entrez l'abréviation"
                  />
                </div>
      </Modal> */}
      
    </div>
  )
}

export default Historique

